.pdf-container {
  position: relative;
  text-align: center;
}

.downloadContainer {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

.download {
  background-color: white;
  border-radius: 2px;
  color: black;
  padding: 15px;
  box-shadow: 5px;
}

.pdf-container img {
  width: 100%;
  cursor: pointer;
}
