.App {
  position: relative;
  box-sizing: border-box;
  height: inherit;
  padding: 0;
  background-color: transparent;
  color: black;
  text-align: center;
  display: flex;
  justify-content: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.scrollContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  box-sizing: border-box;
}

.contentMain {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: stretch;
  z-index: 1;
  box-sizing: border-box;
  max-width: 1000px;
  margin-bottom: 100px;
}

.Sections {
  display: flex;
  flex-direction: column;
  grid-template-columns: 1fr;
  gap: 50px;
  padding: 15px;
  width: 100%;
  box-sizing: border-box;
}

/* Media Query for screen width under 600px */
@media screen and (max-width: 768px) {
  .App {
  }

  .contentMain {
    max-width: 100%;
  }
  .Sections {
    grid-template-columns: 1fr;
    padding: 10px;
    gap: 8vw;
  }
  .logo {
    height: 45px;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
