body {
  margin: 0;
  font-family: "Proxima Nova", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
  box-sizing: border-box;
}

a {
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("/public/assets/fonts/Proxima Nova Regular.woff2") format("woff2"),
    url("/public/assets/fonts/Proxima Nova Light.woff2") format("woff2"),
    url("/public/assets/fonts/Proxima Nova Black.woff2") format("woff2"),
    url("/public/assets/fonts/Proxima Nova Semibold.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 105vh;
  z-index: -1;
  transform: scale(1);
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.backgroundAnimation {
  z-index: 0;
  position: fixed;
  height: 100%;
  width: 100%;
  background: radial-gradient(
    circle at center,
    rgb(255, 255, 255) 0%,
    rgb(255, 255, 255) 10%,
    rgba(255, 254, 234, 1) 13%,
    #c2e6e8 24%,
    #b7e8eb 90%
  );
}

.ocean {
  height: 5%;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  background: #015871;
}

.wave {
  background: url("/public/assets/wave.svg") repeat-x;
  position: absolute;
  top: -198px;
  width: 6400px;
  height: 198px;
  animation: wave 15s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  transform: translate3d(0, 0, 0);
}

.wave:nth-of-type(2) {
  top: -175px;
  animation: wave 22s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite,
    swell 17s ease -1.25s infinite;
  opacity: 1;
}

@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
}

@keyframes swell {
  0%,
  100% {
    transform: translate3d(0, -25px, 0);
  }
  50% {
    transform: translate3d(0, 5px, 0);
  }
}

@media (prefers-color-scheme: dark) {
  body,
  .backgroundAnimation {
    background: radial-gradient(
      circle at center,
      rgb(255, 255, 255) 0%,
      rgb(255, 255, 255) 8%,
      rgb(229, 229, 229) 15%,
      rgb(0, 53, 102) 70%,
      rgb(0, 33, 46) 90%
    );
    color: white;
  }
}
