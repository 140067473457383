.login__textBox {
  padding: 10px;

  border-radius: 0;
  box-sizing: border-box;
  border: 0;
  background-color: rgb(245, 245, 245);
}
.login__btn {
  padding: 10px;
  border: none;
  color: white;
  background-color: black;
}
.login__google {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  color: #282c34;
  font-weight: bold;
  box-sizing: border-box;
  background: #f4f3f1;
  border: 4px solid #4285f4;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
}
.login {
  width: 100%;
  font-size: 16px;
  padding: 30px;
  box-sizing: border-box;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.login__container {
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 350px;
  padding: 20px;
  box-sizing: border-box;
}

.mailApps {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 15px;
  flex-wrap: wrap;
}

.mailApp {
  width: 60px;
  object-fit: contain;
  border-radius: 15px;
  background-color: white;
  filter: drop-shadow(0px 0px 8px #bcbcbc);
}
