.teamMembers {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  width: 100%;
  gap: 20px;
  justify-content: space-around;
  transition: 300ms ease-in-out;
}

.teamMember {
  width: min-content;
  gap: 30px;
  padding: 0px;
  background-color: rgba(0, 0, 0, 0.504);
  width: 280px;
}

.profileImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.square-box {
  position: relative;
  width: 100%;
}
.square-box:before {
  content: "";
  display: block;
  padding-top: 100%;
}

.square-content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  color: white;
  text-align: center;
}

.name {
  font-weight: bolder;
  font-size: 25px;
  color: "#00c7c7";
}

.socialGrid {
  display: flex;
  flex-wrap: wrap;
  gap: 14px;
  align-items: center;
}

.socialLogo {
  width: 40px;
}

.textDescription {
  padding: 10px;
  display: grid;
  gap: 12px;
  grid-template-columns: 1fr;
  text-align: left;
  justify-items: start;
}

@media screen and (max-width: 768px) {
  .teamMember {
    width: 100%;
    max-width: 350px;
    margin-left: 0px;
  }
}

@media (prefers-color-scheme: dark) {
  .teamMember {
    background-color: rgba(0, 0, 0, 0.31);
  }
}
