.headerContainer {
  display: flex;
  /* grid-template-columns: 1fr 1fr 1fr;
  grid-auto-flow: column; */
  position: relative;
  z-index: 9999;
  padding: 15px;
  align-items: ;
  justify-content: space-between;
  box-sizing: border-box;
  height: fit-content;
}

.header {
  position: absolute;
  left: 50%;
  z-index: 999;
  transform: translateX(-50%);
  white-space: nowrap;
  color: rgb(17, 17, 17);
  box-sizing: border-box;
  background-color: white;
  padding: 10px;
}

.email {
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.headerText {
  font-size: larger;
  font-weight: bolder;
}

.loginInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding: 8px;
  box-sizing: border-box;
  background-color: white;
}

.account {
}

.logo {
  -webkit-filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.342));
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.342));
  height: 60px;
}

.megaGrid {
  display: flex;
  align-items: center;
  gap: 30px;
}

@media screen and (max-width: 768px) {
  .header {
    font-size: 12px;
    padding: 8px;
  }

  .headerText {
    font-size: 17px;
  }

  .logo {
    height: 40px;
  }
}
